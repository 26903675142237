<template>
  <div class="pb-20">
    <save-bar :loading="loading" :isDirty="isDirty" @save="saveChanges" @cancel="reset" />
    <Section v-if="!bodyLoading">
      <user-edit store="User" :reference="refId" />
    </Section>
  </div>
</template>

<script>
import ObjectManipulator from '../mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body'
import SaveBar from '../pages/subnav/SaveBar.vue'
import UserEdit from './UserEdit.vue'
import Section from '../ui/Section.vue'

export default {
  mixins: [ObjectManipulator('user', true), BodyMixin],
  name: 'ProfileBody',
  components: {
    Section,
    SaveBar,
    UserEdit
  },
  props: {
    object: {
      default: null
    }
  }
}
</script>
